
























































































import ViewModel from '@/views/contact/Proposal.ts';
export default ViewModel;
